@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&family=Montserrat:wght@400;700&family=Poppins:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  width: 100%;
}

Link {
  text-decoration: none;
  list-style: none;
}

html {
  overflow-x: hidden;
}

body {
  line-height: 1.5;
  font-family: "Hind", serif;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #242424;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-up {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* navigation bar start */
.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 2vh 6vh 2vh 2vh;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  background-color: rgb(240, 241, 243);
  font-family: "Roboto", sans-serif;
}

.email {
  display: flex;
  padding: 0vh 2vh;
  margin-right: 2vw;
  border-right: 1px solid rgb(0, 0, 0);
}

.phone {
  margin-right: 0.4vw;
  font-size: 1.5rem;
  cursor: pointer;

}

.what {
  color: rgb(16, 203, 16);
}

.gmail {
  color: rgb(169, 37, 17);
}

.call {
  color: rgb(35, 17, 169);
}

.left-navbar {
  width: 6vw;
  height: 4vh;

}

.logo img {
  width: 10%;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-cover {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar {
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

#navbar li {
  padding-left: 15px;
}

.nav-hide {
  display: contents;
}

#navbar .nav-link {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all .25s ease;
}

#navbar .nav-link:hover,
#navbar .nav-link:focus,
#navbar .active .nav-link {
  color: #ff3939;
  ;
}

#navbar .btn {
  padding: 0.7rem 1.5rem 0.5rem;
  color: #000000;
}

.btn-main {
  padding: 7px 22px;
  border-radius: 3px;
  background:#175ef7;
  color: #fff;
}

.navbar-toggler {
  padding: 0;
  font-size: 1rem;
  color: #000000;
}

.navbar-toggler span {
  font-size: 2.3rem;
}

@media (max-width: 992px) {
  #navbar .btn {
    margin: 15px 0 10px;
  }

  .logo img {
    width: 12%;
  }

  .nav-hide {
    display: none;
  }
}

@media (max-width: 992px) {
  #navbar li {
    padding-left: 0;
  }

  .modal-container {
    padding: 0rem;
  }

  .cross {
    font-size: 1rem;
    color: white;
  }

  .intro-showgo {
    padding: 0rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  .image-sogo img {
    width: 100%;
    height: 20rem;
  }

  .intro-side {
    width: 100%;
  }

}
@media  (max-width:500px) {
  .email{
    padding: 0;
    padding-left: 12px;
  }
.email a{
  font-size: 0.7rem;
}
.email i{
  font-size: 1rem;
}
.email span{
  font-size: 1rem;
} 
.nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
   padding: 0;
   padding-bottom: 1rem;
    /* padding: 0.5vh 6vh 2vh 2vh; */
  }
}

@media (min-width:300px) and (max-width:600px) {
 

  .logo img {
    width: 15%;
  }

  .email {
    margin-top: 2vh;
    border-right: none;
  }

}

@media (max-width:300px) {
  .responsive {
    padding: 0rem;
  }

  .footer {
    padding-left: 15px;
    padding-right: 12px;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2vh 6vh 2vh 2vh;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    background-color: rgb(240, 241, 243);;
    font-family: "Roboto", sans-serif;
  }

  .email {
    margin-top: 0.5vh;
    border-right: none;
  }

  .phone {
    margin-right: 0vw;
    font-size: 1rem;
  }

  .paragraph {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1rem;
  }

}

/* navigatioin bar end and slide content start */
.poster-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.poster-slider{
  background-image: url("../static_data//web_content//train-railway-speed.webp");
  background-position: center;
  background-size: contain;
  /* background-position: bottom; */
  /* background-repeat:no-repeat; */
  background-repeat: repeat-x;
}

.poster {
  width: 100%;
}
.item  {
  position: relative;
}


.item-cover {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.item .content {
  text-align: justify;
  color: #ffffff;
  width: 45%;

}

.btn {
  padding: 17px 38px;
  border-radius: 3px;
  background: #2ee3ed;
  color: #fff;
  outline: none;
}

.link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
}

.btn-margin{
  margin-left: 2.5rem;
}

.img {
  width: 40%;
}

img {
  width: 100%;
}

.active {
  background-color: #242424;
  color: #fff;
}

.head-name {
  font-size: 2rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-bottom: 1vh;
}

.des {
  margin-bottom: 1vh;
  color: #ffffff;
}
.des h5{
  color: #ffffff;
  text-align: justify;
}
.name-margin {
  margin-top: 5rem;
}

.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid black;
  padding: 0vh 1vh 0vh 1vh;

}

.slider-button {
  width: 18%;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.slide-btn {
  font-size: '2rem';
  margin-right: "0.8rem"
}

.active {
  background-color: #242424;
  color: #fff;
}

@media (max-width:400px) {
  .slider-btn {
    display: none;
  }

}

@media (max-width:500px) {
  .poster-slider {
    padding-bottom: 0.5rem;
  }

  .poster {
    width: 100%;
  }

  .item  {
    position: relative;
  }
  
  .item ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../static_data/web_content/train-railway-speed.webp');
    background-size: cover;
    filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: -1; 
  }


  .item-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .responsive {
    padding: 0rem;
  }
  .item .content {
    text-align: justify;
    color: #fff;
    width: 100%;
    margin-top: 3rem;

  }

  .btn {
    padding: 8px 20px;
    border-radius: 3px;
    background: #2ee3ed;
    color: #fff;
    outline: none;
  }

  .img {
    display: none;
  }

  .active {
    background-color: #242424;
    color: #fff;
  }
.content{
  padding: 0.5rem;
}
  .head-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1vh;
  }
.des h5{
  font-size: 1rem;
}
.des p{
  font-size: 0.8rem;
}

}

/* slide content end and services part start */
.services {
  margin-top: 1rem;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.services-head {
  text-align: center;
  font-size: 1.2rem;
}

.paragraph {
  font-size: 2.5rem;
  font-weight: 600;
  padding: 1rem;
}

.design {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  height: 4px;
  width: 25%;
  background-color: red;
  border-radius: 40px;
}

.circle {
  color: red;
  font-size: 0.3rem;
  margin: 0rem 0.5rem;
}

.slick-slide>div {
  margin: 0px 10px;
  min-height: 450px;

}

.services-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
}

.services-card {
  padding: 0rem 3rem;
  width: 80%;
}
.visit-link{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1.5rem;
  color: black;
}
.app-name a{
  color: rgb(19, 125, 255);
 text-decoration: underline;
}
.app-name a:hover{
  color: rgb(253, 52, 16);
  text-decoration: underline;
 }
/* @media (max-width: 800px) {
  .link-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 1rem;
  }
} */
@media (max-width: 600px) {
  .services-card {
    padding: 0rem;
    width: 80%;
  }
}
@media (max-width: 500px) {
  .visit-link{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 1.5rem;
    color: black;
  }
  .app-name a{
    margin-top: 1rem;
  }
}

@media (max-width: 800px) {
  .footer {
    padding-left: 2px;
    padding-right: 2px;
  }
  .btn-main {
    margin-top: 0.5rem;
  }
  .responsive {
    padding: 12px;
  }
}

.card-show {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 700px) and (max-width: 1280px) {
  .services-card {
    padding: 0rem 2rem;
    width: 100%;
  }
}


.card-show {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-btn {
  padding: 5px 20px;
  border-radius: 40px;
  background: #8c3131;
  color: #fff;
  outline: none;
}

.service-btn:hover {
  background: #ed7e7e;

}

.counter {
  border-bottom: 1px solid black;

  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-container {
  width: 75%;
}

.counter-text {
  font-size: 2.5rem;
  font-weight: bold;
}

.cta {
  background: url('../static_data/web_content/whoweare.jpg');
  background-size: cover;
  padding: 80px 0px;
}
.apply-pop{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cta-block {
  /* background: url('../static_data/web_content/client.jpg') no-repeat; */
  background-size: cover;
}

.who-are {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.comment {
  width: 50%;

}

.service-ullist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.service-list {
  display: flex;
  margin-bottom: 4px;
  color: #000;
  font-weight: 600;
}

.service-list>span {
  margin-right: 8px;
  color: #560d0d;
}

.feedback {
  background: url('../static_data/web_content/client.jpg')fixed 50% 50%;
  background-size: cover;
  padding: 60px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow:0 4px 8px rgba(4, 2, 1, 9.1); ;
}


.feedback-client {
  width: 50%;
  padding: 1rem;
  border: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width:400px) {
  .who-are {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .government h1 {
    text-align: center;
    font-size: 1.2rem;
  }

  .comment {
    width: 95%;
    padding: 0rem;
  }

  .cta {
    padding: 0.5rem;
  }

  .feedback {
    background-size: cover;
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feedback-client {
    width: 80%;
    padding: 1rem;
    border: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width:400px) and (max-width:700px) {
  .who-are {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .comment {
    width: 80%;

  }

  .feedback {
    background-size: cover;
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feedback-client {
    width: 80%;
    padding: 1rem;
    border: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .slider-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid black;
    padding: 0vh 1vh 0vh 1vh;

  }

  .slide-btn {
    font-size: '1rem';
    margin-right: "0.8rem"
  }

  .slider-button {
    width: 18%;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

.quote {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #f5f5f5;
  color: #e84444;
  font-size: 25px;
  line-height: 80px;
  display: inline-block;
  text-align: center;
  margin-bottom: 25px;

}

.footer {
  color: #000000;
  background-color: rgb(240, 241, 243);
  font-family: "Roboto", sans-serif;
}

.footer-social {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.footer-social li {
  margin-left: 1rem;
}

.footer-social .media {
  font-size: 1.5rem;
}

.fotter-list {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.footer-social li:nth-child(1):hover {
  color: rgb(26, 192, 17);
}

.footer-social li:nth-child(2):hover {
  color: rgb(18, 64, 191);
}

.footer-social li:nth-child(3):hover {
  color: rgb(17, 102, 192);
}

.footer-social li:nth-child(4):hover {
  color: rgb(192, 17, 17);
}
.footer-menu li:hover{
color: #175ef7;
}
.fotter-list>span {
  margin-right: 8px;

}

.sub-btn {
  padding: 5px 20px;
  border-radius: 3px;
  background: #2ee3ed;
  color: #fff;
  transition: background 0.15s;
}

.sub-btn:hover{
  color:black;
  background: rgb(163, 163, 231);
}

.custom-modal-body {
  width: 100%;
}
.termcondition-body{
  width: 100%;
  margin-top: 1.5rem;
}
.apply-ollist {
  padding: 1rem;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}
